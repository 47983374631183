import React, { Component } from 'react'
import classNames from 'classnames'
import axios from 'axios'
import qs from 'qs'
import { FormattedMessage, injectIntl } from 'react-intl'
import Recaptcha from 'react-google-recaptcha'

import Button from '../Button'
import InputField from './InputField'

import styles from './contactForm.module.scss'

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

const initialState = {
  name: '',
  email: '',
  topic: '',
  message: '',
  errors: [],
  formSent: false,
}

const recaptchaRef = React.createRef()

class ContactForm extends Component {
  state = initialState

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleRecaptcha = value => {
    this.setState({ 'g-recaptcha-response': value })
  }

  validateForm = () => {
    let errors = []

    if (!this.state['g-recaptcha-response']) {
      errors.push('recaptcha')
    }

    if (this.state.name.trim().length < 1) {
      errors.push('name')
    }

    if (!validateEmail(this.state.email)) {
      errors.push('email')
    }

    if (!errors.length) return true

    this.setState({ errors })
    return false
  }

  handleSubmit = e => {
    e.preventDefault()

    if (this.validateForm()) {
      const form = e.target

      const data = {
        'form-name': form.getAttribute('name'),
        ...this.state,
      }

      // Remove unneeded fields in request
      delete data.errors
      delete data.formSent

      axios({
        url: form.getAttribute('action'),
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
      })
        .then(() => {
          this.setState({
            ...initialState,
            formSent: true,
          })

          recaptchaRef.current.reset()
        })
        .catch(error => console.error(error))
    }
  }

  render() {
    const { intl } = this.props

    return (
      <section id="contactFormSection" className={styles.wrap}>
        <div className={styles.container}>
          <div className={styles.heading}>
            <h2>
              <FormattedMessage id="contact.form.title" />
            </h2>
            {/* <p>
              <FormattedMessage id="contact.form.text" />
            </p> */}

            {this.state.formSent && (
              <p className={styles.successMessage}>
                <FormattedMessage id="contact.form.success" />
              </p>
            )}
          </div>

          <form
            action={`/${intl.locale}/`.concat('success/')}
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-recaptcha="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
            noValidate
          >
            <div className="row">
              <div className={styles.column}>
                <InputField
                  name="name"
                  label="form.name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  required={intl.formatMessage({ id: 'form.required' })}
                  error={this.state.errors.indexOf('name') > -1}
                />
              </div>

              <div className={styles.column}>
                <InputField
                  name="email"
                  type="email"
                  label="form.email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  required={intl.formatMessage({ id: 'form.required' })}
                  error={this.state.errors.indexOf('email') > -1}
                />
              </div>
            </div>

            <div className={styles.inputGroup}>
              <InputField
                name="topic"
                label="form.topic"
                value={this.state.topic}
                onChange={this.handleChange}
              />
            </div>

            <div className={classNames(styles.inputGroup, styles.last)}>
              <label htmlFor="message">
                <FormattedMessage id="form.message" />
              </label>
              <textarea
                id="message"
                name="message"
                value={this.state.message}
                onChange={this.handleChange}
              />
            </div>

            <div className={styles.recaptcha}>
              <Recaptcha
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                onChange={this.handleRecaptcha}
              />
            </div>

            <Button submit text="send" />
          </form>
        </div>
      </section>
    )
  }
}

// Basic email validation check
function validateEmail(email) {
  const regex = RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}')
  return regex.test(email)
}

export default injectIntl(ContactForm)
