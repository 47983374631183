import React from 'react'
import Img from 'gatsby-image'
import Slider from 'react-slick'

import styles from './sliderbig.module.scss'

const SliderBig = props => {

  const images = props.images.edges.sort((a, b) => a.node.name.localeCompare(b.node.name)).reduce((acc, curr) => {
    acc.push(curr.node.childImageSharp.fluid)
    return acc
  }, [])

  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: props.stay ? props.stay : 5000,
    arrows: true,
    dots: true,
    infinite: true,
    speed: props.transition ? props.transition : 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false
  }

  return (
    <section className={styles.wrap}>
      <div className={styles.slider}>
        <Slider {...sliderSettings}>
          {images.map((t, index) => (
            <div key={`anvita-${index}`}>
              <div className={styles.slide}>
                <div className={styles.imageWrap}>
                  <div className={styles.image}>
                    <Img fluid={t} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default SliderBig
