import React from 'react'
import { injectIntl } from 'react-intl'

import Facebook from './icons/Facebook.svg'
import Youtube from './icons/Youtube.svg'
import Instagram from './icons/Instagram.svg'

import styles from './iconBlocks.module.scss'

const IconBlocks = ({ intl }) => {

  const icons = [
    {
      image: Instagram,
      link: 'https://www.instagram.com/anvita.kathak',
      title: 'Instagram'
    },
    {
      image: Facebook,
      link: 'https://www.facebook.com/anvita.kathak',
      title: 'Facebook'
    },
    {
      image: Youtube,
      link: 'https://www.youtube.com/channel/UCE-TQwSsbdojK_L5m_38PXQ',
      title: 'Youtube'
    },
  ]

  return (
    <section className={styles.wrap}>
      <div className="container">
        <ul className={styles.iconUL}>
          {icons.map((block, index) => (
            <li className={styles.column}>
              <a href={block.link} className={styles.block}>
                <img src={block.image} alt={block.title}/>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default injectIntl(IconBlocks)
