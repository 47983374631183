import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import styles from './contactForm.module.scss'

const InputField = props => {
  const classes = classNames(styles.inputGroup, {
    [styles.required]: !!props.required,
    [styles.error]: props.error,
  })

  return (
    <div className={classes}>
      <label htmlFor={props.name} data-required={props.required}>
        <FormattedMessage id={props.label} />
      </label>
      <input
        type={props.type || 'text'}
        id={props.name}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        required={!!props.required}
      />
    </div>
  )
}

InputField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.string,
  error: PropTypes.bool,
}

export default InputField
