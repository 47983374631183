import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link } from '../../i18n'

import styles from './button.module.scss'
import { FormattedMessage } from 'react-intl'

const Button = props => {
  const classes = classNames(styles.button, { [styles.large]: props.large })

  if (props.submit) {
    return (
      <button type="submit" className={classes}>
        <FormattedMessage id={props.text} />
      </button>
    )
  }

  return (
    <Link to={props.to} className={classes} onClick={props.onClick}>
      <FormattedMessage id={props.text} />
    </Link>
  )
}

Button.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string.isRequired,
  submit: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Button
