import React from 'react'
import { graphql } from 'gatsby'
import { withIntl } from '../i18n'

import Layout from '../components/Layout'
import SliderBig from '../components/SliderBig'
import ContactForm from '../components/ContactForm'
import ContentBlock from '../components/ContentBlock'
import SliderInsta from '../components/SliderInsta'
import IconBlocks from '../components/IconBlocks'

const IndexPage = props => {

  return (
    <Layout>
      <SliderBig images={props.data.topImages} />

    <section className="wrap superTitle">
      <div className="container">
        <p>
          A professional and versatile Kathak dancer, Anvita Pandey started training under the tutelage of Sangeet Natak Akademi awardee and Padma Shri Guru Shovana Narayan...
          <a href='about-anvita/'> &nbsp;&nbsp;Read more</a>
        </p>
      </div>
    </section>

      <ContentBlock content={[]} />

      <SliderInsta />

      <IconBlocks />

    </Layout>
  )
}

export const IndexImageQuery = graphql`
  query IndexImageQuery {
    topImages: allFile(
      filter: { relativeDirectory: { eq: "top/home" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2280) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default withIntl(IndexPage)
