import React from 'react'
import Slider from 'react-slick'
import InstagramEmbed from 'react-instagram-embed'

import styles from './sliderinsta.module.scss'
import { FormattedMessage } from 'react-intl'


const SliderInsta = () =>  {
  const insta = [
    'https://www.instagram.com/p/B8yZU6ghZOI/',  
    'https://www.instagram.com/p/B8TkDLnB7z9/',
    'https://www.instagram.com/p/B76Cz1Hh4vd/',
    'https://www.instagram.com/p/B5SWE31hsb-/',
    'https://www.instagram.com/p/B4UYFjuB4ZW/',
    'https://www.instagram.com/p/B4CfwFUhP8z/',
    'https://www.instagram.com/p/B36chVOhXQk/',
    'https://www.instagram.com/p/B3RYpooH_fh/',
    'https://www.instagram.com/p/B3AEw-xhP7d/',
    'https://www.instagram.com/p/B2q8Yu6DlYv/',
    'https://www.instagram.com/p/B2oGgglByWK/',
    'https://www.instagram.com/p/B10mQnkBBDL/',
    'https://www.instagram.com/p/B1jywcGhMIW/',
    'https://www.instagram.com/p/B1CGFa5hBJ9/',
    'https://www.instagram.com/p/B084tIABA_i/',
    'https://www.instagram.com/p/BzQJzcThKRR/',
    'https://www.instagram.com/p/Byr9O8GBqlg/',
    'https://www.instagram.com/p/BykJtsHhAdb/',
    'https://www.instagram.com/p/BxVE1eblOo7/',
    'https://www.instagram.com/p/BxVE1eblOo7/',
    'https://www.instagram.com/p/Bw9ZLYzh8ci/',
    'https://www.instagram.com/p/Bw7fuoOhgpK/',
    'https://www.instagram.com/p/BwOeB5gB8Rd/',
    'https://www.instagram.com/p/Buw-2JNAwlf/',
    'https://www.instagram.com/p/Bul9fqIBtMH/',
    'https://www.instagram.com/p/Bts1uf0hemV/',
    'https://www.instagram.com/p/BtDnecAhNLC/',
    'https://www.instagram.com/p/BrP7OkAh9cR/',
    'https://www.instagram.com/p/BnwQWTfhfBu/',
    'https://www.instagram.com/p/BnqIFiAhrzn/',
    'https://www.instagram.com/p/BnGVI7tgztF/',
  ]

  const sliderSettings = {
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <section className={styles.wrap}>
      <div className={styles.slider}>
        <Slider {...sliderSettings}>
          {insta.map((t, index) => (
            <div key={`insta-${index}`}>
              <div className={styles.slide}>
                <div className={styles.image}>
                  <InstagramEmbed
                    url={t}
                    maxWidth={400}
                    hideCaption={false}
                    containerTagName='div'
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default SliderInsta
